<template>
  <section id="dashboard" class="pb-10">
    <v-row class="mb-5">
      <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
        <TopCard :item="item"/>
      </v-col>
    </v-row>
    <v-card class="custom-border border pa-10 mb-5">
      <section class="d-flex flex-wrap align-center justify-space-between">
        <v-select
          :items="filters"
          v-model="selected"
          class="col-12 col-md-3 f14 fw600 poppins no-outline-custom-field px-0"
          hide-details>
        </v-select>
        <!-- <section class=" d-flex flex-row align-center">
          Filter:
          <v-menu offset-y eager :close-on-content-click="false" content-class="elevation-2">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-bind="attrs" v-on="on" outlined dense readonly 
              v-model="filter"
              hide-details
              :class="domain_err ? '' : 'general-custom-field'"
              class="f14 ml-2"
              placeholder="Select"
              append-icon="mdi-menu-down"/>
            </template>
            <v-card class="pa-2" flat>
              <v-card-subtitle>
                Preview:
                <span class="primary--text">{{ status.text }}</span> 
                <span v-if="date_from !== '' || date_to !== ''">
                  <span v-if="date_from !== '' && date_to !== ''">
                    on
                  </span>
                  <span v-else-if="date_from !== ''">
                    from
                  </span>
                  <span class="primary--text">{{ date_range }}</span>
                </span>
              </v-card-subtitle>
              <v-card-subtitle>
                Comparison
                <v-select 
                  outlined 
                  dense 
                  class="general-custom-field roboto f12 secondary-1--text fw500"
                  :items="status_filter"
                  v-model="status"
                  item-text="text"
                  item-value="value"
                  return-object
                  hide-details>
                </v-select>
              </v-card-subtitle> 
              <v-card-subtitle>
                Date Range:
                <section class="d-flex flex-row align-center roboto f12 secondary-1--text fw500 my-1">
                  FROM: 
                  <v-menu
                    ref="menu_from"
                    v-model="menu_from"
                    :close-on-content-click="false"
                    :return-value.sync="date_from"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_from"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        class="ml-2"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_from"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu_from = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu_from.save(date_from)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </section>
                <section class="d-flex flex-row align-center roboto f12 secondary-1--text fw500 my-1">
                  TO: 
                  <v-menu
                    ref="menu_to"
                    v-model="menu_to"
                    :close-on-content-click="false"
                    :return-value.sync="date_to"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_to"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        hide-details
                        class="ml-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_to"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu_to = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu_to.save(date_to)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </section>
              </v-card-subtitle>

              <v-divider />

              <v-card-subtitle class="d-flex flex-wrap align-center justify-end">
                <v-btn color="primary" dense class="ml-2">
                  APPLY
                </v-btn>
              </v-card-subtitle>
            </v-card>
          </v-menu>
        </section> -->
      </section>
      <UserBarGraph :selected="selected"/>
    </v-card>
    <section v-if="!showUsers" >
      <CourseTable v-if="!loading" @viewList="viewList" :selected="selected"/>
    </section>
    <CourseUserTable 
        v-if="showUsers" 
        :loading="loading" 
        :items="users" 
        @back="back" 
        :listName="listName" 
        :selected="selected"
        :page="page"
        :paginate="paginate"
        :pageCount="pageCount"
        :totalCount="totalCount"
        :filter="filter"
        :value="value"
        @search="(e) => search = e"
        @page="(e) => page = e"
        @paginate="(e) => paginate = e"
      />
  </section>
</template>

<script>
import moment from 'moment'
import TopCard from '../../components/reusable/TopCard.vue'
import UserBarGraph from '../../components/admin/dashboard/UserBarGraph.vue';
import CourseTable from '../../components/admin/dashboard/CourseTable.vue';
import CourseUserTable from '../../components/admin/dashboard/CourseUserTable.vue';

export default {
  components: {
    TopCard,
    UserBarGraph,
    CourseTable,
    CourseUserTable
  },
  created() {
    this.$api.get(`admin/list/cards`).then( res => {
      if(res.data.data) {
        this.items = [
          {
            title: 'Total Enrolled Users',
            count: res.data.data.total_enrolled_users,
            color: '#B5221033', 
            outline: '#B52210'
          },
          // {
          //   title: 'Total List of Courses',
          //   count: res.data.data.total_list_of_courses,
          //   color: '#7BC14533',
          //   outline: '#7BC145'
          // },
          {
            title: 'Published Courses',
            count: res.data.data.published_courses,
            color: '#F8835E33',
            outline: '#F8835E'
          },
          {
            title: 'Total Instructors',
            count: res.data.data.total_instructors,
            color: '#E49E3233',
            outline: '#E49E32'
          },
        ]
      }
    })
  },
  data: () => ({
    items: [
      {
        title: 'Total Enrolled Users',
        count: '-',
        color: '#82828233', 
        outline: '#828282'
      },
      // {
      //   title: 'Total List of Courses',
      //   count: '-',
      //   color: '#7BC14533',
      //   outline: '#7BC145'
      // },
      {
        title: 'Published Courses',
        count: '-',
        color: '#82828233',
        outline: '#828282'
      },
      {
        title: 'Total Instructors',
        count: '-',
        color: '#82828233',
        outline: '#828282'
      },
    ],
    users: [],
    filters: [ 'Total Users per Course' ],
    filters_obj: {},
    selected: 'Total Users per Course',
    showUsers: false,
    listName: '',
    status_filter: [
      {
        text: 'Completed', value: 'completed',
      },
      {
        text: 'Enrolled', value: 'enrolled'
      },
    ],
    status: {
      text: 'Completed', value: 'completed',
    },
    menu_from: false,
    menu_to: false,
    date_from: '',
    date_to: '',
    loading: false,
    pageCount: 1,
    page: 1,
    paginate: '10',
    totalCount: 0,
    search: '',
    filter: '',
    value: ''
  }),
  methods: {
    viewList(e) {
      this.showUsers = true
      this.listName = e.value
      this.loading = true

      let _params = {
        ...e,
        paginate: Number(this.paginate),
        page: this.page,
        search: this.search
      }

      this.filter = e.filter
      this.value = e.value

      this.$api.get('/admin/course/users', { params: _params}).then( res => {
        this.users = res.data.data.data
        this.totalCount = res.data.data.total
        this.pageCount = res.data.data.last_page
        this.page = res.data.data.current_page
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    back() {
      this.search = ''
      this.showUsers = false
      this.users = []
      this.selected = this.selected
    }
  },
  mounted() {
    this.$api.get('/admin/custom-registration/field/lists').then( res => {
        this.filters.push(...res.data.data)
        this.loading1 = false
    }).catch(() => {
      this.loading1 = false
    })
  },
  watch: {
    selected(val) {
      this.showUsers = false
    },
    page(){
      this.viewList({ value: this.listName, filter: this.selected })
    },
    paginate(){
      this.page = 1
      this.viewList({ value: this.listName, filter: this.selected })
    },
    search(){
      this.page = 1

      if(this.showUsers) {
        this.viewList({ value: this.listName, filter: this.selected })
      }
    }
  },
  computed: {
    date_range(){
      if(this.date_from != '' && this.date_to != ''){
        return `${moment(this.date_from).format('LL')} ~ ${moment(this.date_to).format('LL')}`
      } else if (this.date_from != '') {
        return `${moment(this.date_from).format('LL')}`
      } else if (this.date_to != '') {
        return `${moment(this.date_to).format('LL')}`
      }
    }
  }

}
</script>