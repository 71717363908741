<template>
  <section>
    <!-- <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :width="400"
      :height="400"
      v-if="!loading"
      class="poppins"
    /> -->
    <circular v-show="loading" class="my-5"/>
    <canvas v-show="!loading" ref="chart" style="width: 300px; min-height: 150px" class="f14"></canvas>
  </section>
  </template>
  
<script>
import { mapState } from 'vuex'
//import { Bar } from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';
import { Title,
  Tooltip,
  Legend, BarController, CategoryScale, LinearScale} from 'chart.js';

// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   BarController
// } from 'chart.js'

export default {
  name: 'BarChart',
  components: {
    //Bar
  },
  props: ['selected'],
  mounted() {
    //ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
    Chart.register(BarController, CategoryScale, LinearScale, Title, Tooltip, Legend);
  },
  methods: {
    getData() {
      if(this.chart) {
        this.chart.destroy();
      }
      
      this.loading = true
      this.$api.get('/admin/total-users-per-course', {params: {
          filter: this.selected
        }}).then(res => {
          
        this.loading = false
          let labels = []
          Object.keys(res.data.data).forEach(val => {
            if(val.length > 20) {
              labels.push(val.slice(0,17) + '...')
            } else {
              labels.push(val)
            }
        });
        this.chartData.labels = labels
        this.chartData.datasets[0].data = Object.values(res.data.data)
        this.chartData.datasets[0].backgroundColor = this.$vuetify.theme.currentTheme.primary

        let ctx = this.$refs.chart.getContext('2d');
            
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: this.chartData,
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: true,
            },
        });
      })
    }
  },
  data: () => ({
    loading: false,
    chartData: {
      labels: ['label1'],
      datasets: [
        {
          label: 'No. of Students',
          backgroundColor: '',
          data: [0]
        }
      ]
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    },
    chart: null
  }),
  created() {
    this.getData()
  },
  computed: {
    ...mapState({
      customization: (state) => state.customization
    }),
  },
  watch: {
    selected() {
      this.getData()
    }
  }
}
</script>
  